const GoogleAccount = function () {
    const DISCOVERY_DOC = [
        'https://www.googleapis.com/discovery/v1/apis/people/v1/rest',
        'https://www.googleapis.com/discovery/v1/apis/admin/directory_v1/rest'
    ];
    const SCOPES = [
        'https://www.googleapis.com/auth/contacts.readonly',
        'https://www.googleapis.com/auth/contacts.other.readonly',
        'https://www.googleapis.com/auth/admin.directory.group.readonly',
        'https://www.googleapis.com/auth/admin.directory.group.member.readonly',
        'https://www.googleapis.com/auth/admin.directory.user.readonly',
    ];

    // Flow Cloud Project
    const clientId = '737715663279-ngf1r48m7ncp9jutg31crdecpc5fknci.apps.googleusercontent.com';
    const developerKey = 'AIzaSyA_9imE4JmxGSlrBTHmClyFRqaEni5s65g';

    let tokenClient;
    let loadCallback;
    let successCallback;
    let pageToken;

    return {
        openGoogleAccount: openGoogleAccount,
        revokeToken: revokeToken,
        setPageToken: setPageToken,
        getContactUserList: getContactUserList,
        getWorkspaceUserList: getWorkspaceUserList,
    }

    function openGoogleAccount(props) {
        const {
            _loadCallback = Mutil.noop,
            _successCallback = Mutil.noop,
        } = props;

        Mutil.lib.getGoogleAccountApi().then(async () => {
            if (typeof gapi === "undefined" || gapi === null) return;
            if (parseInt(Often.getIEVersion(), 10) < 9) {
                Often.toast("error", i18next.t(main.alert.unSupportIE));
                return
            }

            loadCallback = _loadCallback;
            successCallback = _successCallback;

            await onAuthApiLoad();
            await initToken();
        })
    }

    function onAuthApiLoad() {
        gapi.load('client', async () => {
            await gapi.client.init({
                apiKey: developerKey,
                discoveryDocs: DISCOVERY_DOC,
            });
        });
    }

    async function initToken() {
        tokenClient = google.accounts.oauth2.initTokenClient({
            client_id: clientId,
            scope: SCOPES.join(' '),
            callback: (response) => {
                gapi.client.setToken({access_token: response.access_token});
            },
            immediate: true,
            cookie_policy: 'none'
        });

        await Mutil.delay(100);
        if (gapi.client.getToken() === null) {
            tokenClient.callback = async (res) => {
                if (res.error) throw (res);
                await loadCallback();
            }
            tokenClient.requestAccessToken({prompt: 'consent'});
        } else {
            await loadCallback();
        }
    }

    function revokeToken() {
        const token = gapi.client.getToken();
        if (!token) return;
        // google.accounts.oauth2.revoke(token.access_token);
        gapi.client.setToken('');
    }

    function setPageToken(token = ''){
        pageToken = token;
    }

    async function getContactUserList() {
        try {
            const response = await gapi.client.people.otherContacts.list({
                'pageSize': 100,
                'readMask': 'names,emailAddresses,photos',
                'pageToken': pageToken,
            });
            pageToken = response.result.nextPageToken;
            successCallback({'apiKind': 'Contact', ...response.result});
        } catch (err) {
            Mutil.mlog("GooglePeople::getContactUserList", {err});
        }
    }

    async function getWorkspaceUserList() {
        try {
            let response = await gapi.client.directory.users.list({
                'customer': 'my_customer',
                'maxResults': 100,
                'orderBy': 'familyName',
                'pageToken': pageToken,
                'viewType': 'domain_public',
                'query': '', // 검색어
            });
            pageToken = response.result.nextPageToken;
            successCallback({'apiKind': 'Workspace', ...response.result});
        } catch (err) {
            Mutil.mlog("GoogleDirectory::getWorkspaceUserList", {err});
        }
    }
}();
