var DocViewer = (function () {

    return {
        openDocViewer: openDocViewer,
    }

    function openDocViewOnTablet(fileJson) {
        if (Often.isTablet() && ServerChecker.isKsfc) {
            let returnUrl = `ksfcflow://${Often.getLocOrigin()}/flow-renewal/view/thirdparty/calendar_plus_tablet_view.jsp`;
            let filePath = replaceUrlHttps(fileJson.FILE_PATH);
            let fileName = FileUtil.getFileName(fileJson);
            location.href = KsfcConfig.openPolarisViewer(returnUrl, filePath, fileName);
            return true;
        }
        if (Often.isTablet()) {
            const agent = navigator.userAgent;
            fileJson.FILE_DOWN_URL = fileJson.FILE_PATH;
            if (agent.indexOf('iPhone') >= 0 || agent.indexOf('iPad') >= 0) {
                window.webkit.messageHandlers.FLOW_WEB_BRIDGE.postMessage(JSON.stringify(fileJson));
            } else if (agent.indexOf('Android') >= 0) {
                window.FLOW_WEB_BRIDGE.openDocViewOnTablet(JSON.stringify(fileJson));
            }
            return true;
        }
        return false;
    }

    function openMobileSurveyDocViewer({ATCH_SRNO, ATCH_URL, ORCP_FILE_NM}) {
        const agent = navigator.userAgent;
        if (agent.indexOf('iPhone') >= 0 || agent.indexOf('iPad') >= 0) {
            const fileInfo = ATCH_SRNO + "," + ATCH_URL + "," + ORCP_FILE_NM;
            window.webkit.messageHandlers.surveyDocViewer.postMessage(fileInfo);
        } else if (agent.indexOf('Android') >= 0) {
            window.FLOW_SURVEY_BRIDGE.surveyDocViewer(ATCH_SRNO, ATCH_URL, ORCP_FILE_NM);
        }
    }

    function openDocViewer(fileJson, isSecretMsg,isSurveyDiscription, docMode) {
        // if(!Often.isFunc(Func.ENTER.DOCVIEWER_ONLY)) return FileUtil.saveFile(fileJson);
	    if (Often.isFunc(Func.ENTER.FILE_DOWNLOAD_ONLY)&& !(ServerChecker.isKsfc && Often.isTablet())) return FileUtil.saveFile(fileJson);
        if(Often.isFunc(Func.ENTER.POLARIS_DOCS)) {
            const allowedExtensions = ['xlsx', 'xls', 'ppt', 'pptx', 'docx', 'doc', 'hwp', 'hwpx'];
            const fileExtension = (fileJson[0].FILE_NM ? fileJson[0].FILE_NM : fileJson[0].ORCP_FILE_NM).split('.').pop().toLowerCase();

            if(!allowedExtensions.includes(fileExtension)){
                return FileUtil.saveFile(fileJson);
            }
        }
        if (!fileJson || fileJson.length === 0) return false;
        var fileJsonData = fileJson[0];
        let mode = (isSurveyDiscription ? "SURVEY-" : "") + "DOC-VIEWER";
        if(Often.isFunc(Func.ENTER.POLARIS_DOCS) && docMode === "PORARIS_DOCS"){
            mode = "PORARIS_DOCS";
        }

        if(Often.isFunc(Func.ENTER.POLARIS_CONVERTER) && (docMode === "PORARIS_CONVERTER" || docMode === "DOC-VIEWER")){
            mode = "PORARIS_CONVERTER";
            Mutil.openLoadingPop($("body"))
        }

        FileUtil.checkFileOnServer(fileJson, mode,function (fileData) {
            var fileId = fileJsonData.RAND_KEY + "_" + fileJsonData.ATCH_SRNO;
            var docViewerData = Often.null2Void(fileData[0], {});

            const isSurveyWebView = isSurveyDiscription && !!window?.isWebView;
            if (isSurveyWebView && (Often.isMobile() || Often.isTablet())) {
                const surveyFileJson = {
                    ATCH_SRNO: fileJsonData.ATCH_SRNO,
                    ATCH_URL: docViewerData.FILE_DOWN_URL,
                    ORCP_FILE_NM: fileJsonData.ORCP_FILE_NM,
                }
                return openMobileSurveyDocViewer(surveyFileJson);
            }

            if (!isSurveyDiscription && openDocViewOnTablet(Object.assign(fileJson[0], docViewerData))) return;

            let viewerUrl = Often.null2Void(docViewerData.VIEWER_URL);
            let polarisEditorUrl = Often.null2Void(docViewerData.EDIT_VIEWER_URL);
            if ("" === viewerUrl || "null" === viewerUrl) return Often.toast("error", docViewerData.ERR_MSG);
            var filePath = replaceUrlHttps(docViewerData.FILE_PATH);
            var fileDownUrl = Often.null2Void(docViewerData.FILE_DOWN_URL);
            var isEmptyDownloadUrl = (fileDownUrl === "" || isSecretMsg)
            var tempJson = {
                removePrintPermission: isEmptyDownloadUrl,
                downloadUrl: (isEmptyDownloadUrl ? "" : fileDownUrl),
                secretMsgYn: isSecretMsg ? "Y" : "N",
            };

            const viewerDownYn = fileJsonData.VIEWER_DOWN_YN || "Y";

            if(Often.isFunc(Func.ENTER.POLARIS_DOCS) && docMode === "PORARIS_DOCS"){
                (window.ElectronApi && ElectronApi.Comm.isElectron()) ? location.href=polarisEditorUrl : window.open(polarisEditorUrl, '_blank');
            } else if(Often.isFunc(Func.ENTER.POLARIS_CONVERTER) && (docMode === "PORARIS_CONVERTER" || docMode === "DOC-VIEWER")) {
                Mutil.closeLoadingPop();
                window.open(viewerUrl, '_blank');
            } else {
                Often.submitForm("viewer_form", viewerUrl, fileId, $.extend(tempJson, {
                    fid: fileId,
                    filePath: filePath,
                    fileName: FileUtil.getFileName(fileJsonData),
                    viewerDownYn: viewerDownYn,
                    userId: _USER_ID,
                }));
            }
        }
        , function() {
            Mutil.closeLoadingPop();
        })
    }

    function isImageFile(fileArray){
        const fileSize = fileArray[0].FILE_SIZE;
        const fileName = fileArray[0].FILE_NM;
        return ImageUtil.isImageExtension(fileName) && ImageUtil.isImageSize(fileSize);
    }

    function replaceUrlHttps(fileUrl) {
        var flowFileIndex = fileUrl.indexOf("/FLOW_DOWNLOAD_R001.act");
        var locationOrigin = Often.getLocOrigin();
        var isLocal = (locationOrigin.indexOf("localhost") > -1);
        if (flowFileIndex === -1) return fileUrl;
        if (isLocal) {
            //return "https://flowtest.info/" + fileUrl.substr(flowFileIndex)
            return "http://localhost:8080" + fileUrl.substr(flowFileIndex)
        } else if(Often.getLocOrigin() === 'https://bmwflow.com') {
            return 'http://bmwflow.com' + fileUrl.substr(flowFileIndex);
        } else {
            return locationOrigin + "/" + fileUrl.substr(flowFileIndex)
        }
    }

})();
