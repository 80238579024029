const CustomPopupEvent = function () {

    return{
        setChatExportEvent:setChatExportEvent,
    }

    async function setChatExportEvent($customTempPopup){
        let startDate, endDate;
        defaultClosePopupEvnet($customTempPopup);
        await setFlatpickerEvent();
        setRadioClickEvent();

        let prevDate = "";
        setConfirmEvent();
        async function setFlatpickerEvent(){
            //기본 옵션
            const options = {
                dateFormat: "Y-m-d",
                maxDate: new Date(),
                disableMobile: true,
                locale: Internationalization.getCurrentLanguage(),
            };

            //커스텀 옵션
            let customOption = {};
            if(ServerChecker.isSamsungFire){
                const currentDate = new Date();
                currentDate.setDate(new Date().getDate()-14);
                customOption = {
                    minDate: currentDate,
                }
            }

            const startOption = {
                defaultDate: new Date().setDate(new Date().getDate() - 7),
                onChange: function (selectedDates, dateStr, instance){
                    if(startDate.latestSelectedDateObj > endDate.latestSelectedDateObj) {
                        Often.toast("error", i18next.t(main.datePicker.wrongDate));
                    } else {
                        $customTempPopup.find("#startDate .js-date").text(dayjs(startDate.latestSelectedDateObj).format("YYYY-MM-DD"));
                        endDate.set("minDate",startDate.latestSelectedDateObj);
                    }
                }
            };

            const endOption = {
                defaultDate: new Date(),
                onChange: function (selectedDates, dateStr, instance){
                    if(startDate.latestSelectedDateObj > endDate.latestSelectedDateObj) {
                        Often.toast("error","마감일은 시작일보다 앞설 수 없습니다.");
                    } else {
                        $customTempPopup.find("#endDate .js-date").text(dayjs(endDate.latestSelectedDateObj).format("YYYY-MM-DD"));
                        startDate.set("maxDate",endDate.latestSelectedDateObj);
                    }
                }
            };

            startDate = await DatePicker.simpleMakeFlatpicker($customTempPopup.find("#startDate"), Object.assign(options,customOption,startOption));
            endDate = await DatePicker.simpleMakeFlatpicker($customTempPopup.find("#endDate"), Object.assign(options,customOption,endOption));

            $customTempPopup.find("#startDate .js-date").text(dayjs(startDate.latestSelectedDateObj).format("YYYY-MM-DD"));
            $customTempPopup.find("#endDate .js-date").text(dayjs(endDate.latestSelectedDateObj).format("YYYY-MM-DD"));
        }

        function setRadioClickEvent(){
            const $radioWrap = $customTempPopup.find(".chat-export-popup .radio-wrap");
            const $radioLabel = $radioWrap.find(".btn-radio-label");
            $radioLabel.on("click", function(e){
                e.stopPropagation();
                e.preventDefault();

                const $eTarget = $(e.target);
                const $radioBtn = $eTarget.closest("li").find(".btn-radio");
                if (!$radioBtn.prop("checked")) $radioBtn.prop("checked", true);

                const $durationInputBox = $customTempPopup.find(".export-input-wrap");
                if($radioBtn.val() === "duration"){
                    $durationInputBox.css("display","flex");
                } else if($radioBtn.val() === "all"){
                    $durationInputBox.hide();
                } else {
                    console.log("error");
                }
            });

        }

        function setConfirmEvent() {
            const confirmBtn = $customTempPopup.find(".submit-event");
            confirmBtn.off().on("click",async function () {
                const selectType = $customTempPopup.find(".chat-export-popup .radio-wrap").find("input[name='period']:checked").val();
                $customTempPopup.remove();
                const chatExportLoading = $("#chatExportLoading").html();
                const $loading = getPopupHtml(chatExportLoading);

                let inputJson = {
                    ROOM_SRNO: _ROOM_SRNO,
                    CHAT_SRCH_GB: "DL",
                    USE_INTT_ID: _USE_INTT_ID,
                    MODE: "DOWNLOAD",
                    PG_NO: 1,
                    PG_PER_CNT: 100, //일단은 100개씩 가져와 봄
                };

                if(selectType==="duration"){
                    inputJson.START_DATE = dayjs(startDate.latestSelectedDateObj).format("YYYYMMDD000000");
                    inputJson.END_DATE = dayjs(endDate.latestSelectedDateObj).format("YYYYMMDD235959");
                }

                try{
                    let result = [];

                    //채팅방 정보
                    const sendienceResult = await Ajax.executeApi("COLABO2_CHAT_SENDIENCE_R001", inputJson, function (dat) {
                        result.push(convertTitle(dat));
                    });

                    if(sendienceResult.COMMON_HEAD.ERROR){
                        throw new Error("fail");
                    }

                    //채팅 내용
                    let nextYn = 'Y';
                    let currentCnt=0,totalCnt=0;
                    while(nextYn === 'Y'){
                        const chatMsgResult = await Ajax.executeApi("COLABO2_CHAT_MSG_R001", inputJson, function (dat) {
                            if(inputJson.PG_NO===1){
                                totalCnt = dat.CHAT_CNT;
                            }
                            currentCnt += dat.MSG_REC.length;
                            result.push(convertMsg(dat));
                        });

                        if(chatMsgResult.COMMON_HEAD.ERROR){
                            throw new Error("fail");
                        }
                        nextYn = chatMsgResult.NEXT_YN;
                        inputJson.PG_NO++;
                        updateProgressBar($loading,currentCnt,totalCnt);
                    }

                    //다운로드
                    downloadText(result);
                } catch (e){
                    console.error(e);
                } finally {
                    $loading.remove();
                }

            })

            function convertTitle(dat){
                const room_nm = window.globalValue.chatInfo.ROOM_NM;
                const start = Time.format(window.globalValue.chatInfo.RCVR_RGSN_DTTM,"type5"); //채팅방 개설 날짜
                const end = Time.format(dayjs().format("YYYYMMDDHHmmss"),"type5");

                //TODO. 다국어처리
                const title = `${room_nm} 대화내용\n`;
                const period = `채팅방 대화내용 기간 : ${start} ~ ${end}\n`;
                const saveDate = `채팅방 저장 일시 : ${dayjs().format("YYYY-MM-DD HH:mm:ss")}\n`;
                const rcvrUsers = `참여자 : ${dat.SENDIENCE_REC.map(function (sendience){
                    return sendience.RCVR_USER_NM
                }).join(",")}\n`;

                return title + period + saveDate + rcvrUsers;
            }

            function convertMsg(dat){
                const result = dat.MSG_REC.map(function (msg){
                    let temp="";
                    if(prevDate !== dayjs(msg.RGSN_DTTM).format("YYYY년 MM월 DD일")) {
                        prevDate = dayjs(msg.RGSN_DTTM).format("YYYY년 MM월 DD일");
                        temp += `\n----------${prevDate}----------\n`;
                    }
                    if(msg.MSG_GB === "C" || msg.MSG_GB === "A") temp += `[${dayjs(msg.RGSN_DTTM).format("HH:mm:ss")}] [${msg.RGSR_NM}] [${msg.RGSR_DVSN_NM}] ${msg.CNTN.replace(/&lt;/g, "<").replace(/&gt;/g, ">").replace(/<br>/g, "\n")}\n`;
                    else if(msg.MSG_GB === "I") temp += `[${dayjs(msg.RGSN_DTTM).format("HH:mm:ss")}] [${msg.RGSR_NM}] [${msg.RGSR_DVSN_NM}] : (이미지)\n`;
                    else if(msg.MSG_GB === "F") temp += `[${dayjs(msg.RGSN_DTTM).format("HH:mm:ss")}] [${msg.RGSR_NM}] [${msg.RGSR_DVSN_NM}] : (첨부파일 : ${msg.FILE_REC[0].FILE_NAME})\n`;
                    else if(msg.MSG_GB === "E") temp += `[${dayjs(msg.RGSN_DTTM).format("HH:mm:ss")}] [${msg.RGSR_NM}] [${msg.RGSR_DVSN_NM}] : (이모티콘) ${msg.CNTN}\n`;
                    else if(Often.null2Void(msg.VC_SRNO,"0") !== "0") temp += `[${dayjs(msg.RGSN_DTTM).format("HH:mm:ss")}] [${msg.RGSR_NM}] [${msg.RGSR_DVSN_NM}] : (${msg.RGSR_NM}님의 화상회의)\n`
                    else temp += `[${dayjs(msg.RGSN_DTTM).format("HH:mm:ss")}] [${msg.RGSR_NM}] [${msg.RGSR_DVSN_NM}] : ${msg.CNTN.replace(/&lt;/g, "<").replace(/&gt;/g, ">").replace(/<br>/g, "\n")}\n`
                    return temp;
                }).join("");
                return result;
            }

            function updateProgressBar($loading,currentDataSize,totalDataSize){
                const percent = Math.min(100, Math.round((currentDataSize / totalDataSize) * 100));
                $loading.find(".progress-wrap .js-progress-bar.progress-graph-bar").css("width",`${percent}%`);
                $loading.find(".progress-wrap .percentage").text(`${percent}%`);
            }

            function downloadText(result){
                const blob = new Blob(result, {
                    type: 'text/plain;charset=UTF-8',
                });
                const blobUrl = window.URL.createObjectURL(blob);
                const roomChatTtl = $("#miniMessengerStatus").find(".js-room-name").text();
                const filename = `flow${dayjs().format("YYYYMMDD")}_${dayjs().format("HHmm")}_${roomChatTtl}`;

                const link = document.createElement('a');
                link.href = blobUrl;
                link.download = `${filename}.txt`;
                link.click();
                link.remove();

                window.URL.revokeObjectURL(blobUrl);
            }
        }
    }

    function defaultClosePopupEvnet($customTempPopup){
        $customTempPopup.on("click",function(e){
            e.stopPropagation();
            e.preventDefault();
            const $eTarget = $(e.target);

            let $closeEvent = $eTarget.findUp(".close-event");
            let $cancelEvent = $eTarget.findUp(".cancel-event");

            if($closeEvent.length > 0) $customTempPopup.remove();
            if($cancelEvent.length > 0) $customTempPopup.remove();
        })
    }

    function getPopupHtml(customWrap){
        const popupWrap = $("#popupWrap").html();
        const tempHtml = ListHelper.replaceJson(popupWrap, {
            contents: customWrap,
        });
        return $(tempHtml).appendTo("body");
    }
}();
