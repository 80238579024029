//어떤 것도 의존해서는 안 됨
var MobileApp = (function () {

    var _ENTER_YN = undef2v(window._ENTER_YN, "N");
    var _GUBUN = undef2v(window._GUBUN, "");
    var _INVT_KEY = undef2v(window.INVT_KEY, location.pathname);

    var mobileSchemeJson = {
        "N": location.host.includes("morningmate") ? "morningmate" : "bizplaycollabo",
        "Y": "bizplaycollaboent", //엔터
        "G": "bizplaycollabogtalk", //지톡
        "J": "joinsflow", //조인스
        "K": "ktworks", //케이티
        "SSGC" : "casamiaflow",// 까사미아
        "SEM":"sem", //삼성전기
        "MRA":"miraeasset",
        "POSCOICT":"poscodxflow",
    }

    var appStoreJson = {
        "N": location.host.includes("morningmate") ? "https://apps.apple.com/kr/app/morningmate/id6445914029"
            : "https://itunes.apple.com/kr/app/%ED%94%8C%EB%A1%9C%EC%9A%B0-%EC%89%AC%EC%9A%B4-%ED%98%91%EC%97%85%ED%88%B4/id939143477?mt=8",
        "Y": "https://itunes.apple.com/kr/app/kollabo-hyeob-eob!-ijebuteo/id1280834744?l=ko&ls=1&mt=8", //엔터
        "G": "https://itunes.apple.com/kr/app/gtalk-%EC%A7%80%ED%86%A1/id1334605799?mt=8", //지톡
        "J": "https://apps.apple.com/kr/app/id1484802560", //조인스
        "K": "https://apps.apple.com/us/app/id1530166948", //케이티
    }

    var playStoreJson = {
        "N": location.host.includes("morningmate") ? "com.morningmate.flow" : "com.webcash.bizplay.collabo",
        "Y": "team.flow.flowEnt", //엔터
        "G": "team.flow.GTalkEnt", //지톡
        "J": "com.joins.flow2", //조인스
        "K": "team.flow.ktflow", //케이티
    }

    var timer;	// 타이머
    var schInterval; // 인터벌

    return {
        goMarketOrApp: goMarketOrApp,
        executeApp: executeApp,
        goAppStoreOrPlayStore: goAppStoreOrPlayStore,
        goPostByTinyUrl: goPostByTinyUrl,
        enterSchemeCheck: enterSchemeCheck,
    }

    function goMarketOrApp(json) {
        // 앱이 설치 되어있는지 체크
        executeApp(json);
        //schInterval = setInterval(intervalSch, 500);
        timer = setTimeout(function () {
            goAppStoreOrPlayStore()
            //clearInterval(schInterval);
        }, 2000);
    }

    function executeApp(json, enterCode) {
        json = json || {}
        enterCode = enterCode || _ENTER_YN;
        location.href = makeQueryString(mobileSchemeJson[enterCode] + "://open", json);
    }

    function goAppStoreOrPlayStore(gb, enterCode) {
        gb = gb || _GUBUN;
        enterCode = enterCode || _ENTER_YN;
        if ("MRA" === enterCode) return location.href = "https://mdmuser.miraeassetsecurities.com/mdownload/";
        if ("IPHONE" === gb || "IPAD" === gb) return location.href = (appStoreJson[enterCode]);
        location.href = makeQueryString("market://details", {
            id: playStoreJson[enterCode],
            referer: _INVT_KEY,
        });
    }

    function makeQueryString(url, json) {
        var returnUrl = url;
        var i = 0;
        for (var key in json) {
            returnUrl += (i === 0 ? "?" : "&") + n2v(key) + "=" + n2v(json[key]);
            i++;
        }
        return returnUrl;
    }

    function goPostByTinyUrl(_tinyUrl, enterCode) {
        if (_tinyUrl === "") return;
        if (_tinyUrl.indexOf("/") === -1) return executeApp({link_url: _tinyUrl}, enterCode);
        var tinyUrlArr = _tinyUrl.split("/");
        var projectSrno = n2v(tinyUrlArr.length > 1 ? tinyUrlArr[1] : "");
        var postSrno = n2v(tinyUrlArr.length > 2 ? tinyUrlArr[2] : "");
        executeApp({
            colabo_srno: projectSrno,
            colabo_commt_srno: postSrno,
        }, enterCode)
    }

    function n2v(value, str) {
        if (value == null || value === "" || typeof (value) === "undefined" || typeof (value) === undefined ||
            value === "null" || value === "undefined" || value === undefined) {
            return (typeof (str) == "string") ? str : "";
        } else {
            return value;
        }
    }

    function undef2v(globalVariable, defaultVariable) {
        return typeof globalVariable === "undefined" || !globalVariable ? defaultVariable : globalVariable
    }

    // 인터벌, 타이머 삭제
    function clearTimer() {
        clearInterval(schInterval);
        clearTimeout(timer);
    }

    // 인터벌 마다 동작할 기능
    function intervalSch() {
        // 매 인터벌 마다 웹뷰가 활성화 인지 체크
        if (document.webkitHidden || document.hidden) {// 웹뷰 비활성화
            clearTimer();// 앱이 설치되어있을 경우 타이머 제거
        }
    }

    function enterSchemeCheck(enterCode) {
        let useInttId = Often.null2Void($('body').attr('data-enter-id'),'');
        if(useInttId === '') useInttId = enterCode;
        switch (useInttId) {
            case "MRA_1":
            case "SSGC_1":
            case "SEM_1":
            case "POSCOICT_1":
                useInttId = useInttId.split('_')[0];
                break;
            default:
                useInttId = 'Y'
                break;
        }
        return useInttId;
    }
})()
