// 기존 PopupDraw.js에서 addEventOnTempPopup시 preventDefault 문제

const CustomPopupDraw = function () {
    return {
        drawChatExportPopup:drawChatExportPopup,
    }

    function drawChatExportPopup(){
        const chatExportWrap = $("#chatExportWrap").html();
        const $customTempPopup = getPopupHtml(chatExportWrap);
        $customTempPopup.find(".chat-export-popup").show();

        CustomPopupEvent.setChatExportEvent($customTempPopup);
    }

    function getPopupHtml(customWrap){
        const popupWrap = $("#popupWrap").html();
        const tempHtml = ListHelper.replaceJson(popupWrap, {
            contents: customWrap,
        });
        return $(tempHtml).appendTo("body");
    }
}();
