var id = ''; //gtm.js 이슈 해결을 위하여

const minify = (isReal) => {
    Often.setCookie("MINIFY_YN", isReal ? "Y" : "N");
    location.reload();
}

function requireModule(nameArr = [], {on = []} = {}) {
    const undefinedModules = [];
    const isNameArrString = typeof nameArr === 'string';
    if (isNameArrString) nameArr = [nameArr];
    if (typeof on === 'string') on = [on];
    return function () {
        const modules = nameArr.map(name => {
            const module = window[name];
            if (!module) undefinedModules.push(name);
            return module;
        });
        if (undefinedModules.length > 0) {
            if (Often.isServerModeByHost('ALL_TEST')) {
                const trace = Often.getStackTrace();
                if (on.length === 0) {
                    console.warn(`Not Found [${undefinedModules.join(',')}] Module! ::: ${trace[2]}`);
                } else if (false === on.reduce((acc, cur) => (Often.isAct(cur) || acc), false)) {
                    console.log(`Only Use [${undefinedModules.join(',')}] Module On [${on.join(', ')}]! ::: ${trace[2]}`);
                }
            }
        }
        return isNameArrString ? modules[0] : modules;
    }
}

const Often = (function () {
    const StatusSocket = requireModule('StatusSocket', {on: ['miniMain', 'messenger']});

    const toast = (type, msg) => PopupDraw.drawToast({type, msg})
    const getLocOrigin = () => location.protocol + "//" + location.host;
    const isAct = (key) => location.pathname.includes(key);
    const isCookieExist = (value) => document.cookie.includes(null2Void(value));
    const isMadras = () => _USE_INTT_ID === "UTLZ_1608261809693" || _USE_INTT_ID === "BFLOW_300000000563";
    const numberWithCommas = x => null2Void(x, '0').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const isItem = ($eTarget, sizzleName) => {
        return (sizzleName instanceof Array ? sizzleName : [sizzleName])
            .filter(v => $eTarget.closest(v).length > 0).length > 0
    }

    return {
        toast, getLocOrigin, isAct, isCookieExist, isMadras, numberWithCommas, isItem,

        //cookie
        getCookie,
        setCookie,
        setDomainCookie,

        local2session,
        session2local,

        //only-get
        getDeviceJson,
        getIEVersion,
        getClientOSInfo,
        getUUID,
        getRandomDeviceId,
        getJsonToParse,
        getDateFormat,
        getAttrs,
        getServiceName,
        getRandomInt,

        //convert
        null2Void,
        chatNull2Void,
        undefined2Obj,
        getAdmin,
        getAdminPage,

        //isBoolean
        isNull,
        isFunc,
        isServerModeByHost,
        isBrowser,
        isLowIeBrowser,
        isMessenger,
        isDeadlineExceeded,
        isSameDay,
        isMobile,
        isTablet,
        isJson,
        isSubDomainUrl,
        isElectron,
        isGlobal,
        isForwardContents,
        isTinyUrlIncluded,
        //etc
        submitForm,
        getServerModeByHost,

        clog,
        elog,
        copyUrl,
        tryLogout,
        logoutDirect,
        diffSeconds,
        showOrHideByFunc,
        showOrRemoveByFunc,
        httpTextSupplement,
        undef2v,
        getLocationQueryParamJson,
        isGuest,
        hideOrShowByFunc,
        removeOrShowByFunc,
        includeHTML,

        isEmpty,
        checkInnerIp,
        getStackTrace,
        parseJwt,
        convertToDateString,
        replaceFromIndex,
        getClientIp,

        logoutOnBrowserClose,

        convertExceptionalOpenGraphImageUrl,

        //for electron
        electronSetFlowlangToElectronConfig,
        electronIsSmallerThanInputVersion,

        openTestSellmateOauthWindow,
        openTestSellmatPosOauthWindow,
    }

    function getStackTrace() {
        let stack;
        try {
            throw new Error('');
        } catch (error) {
            stack = error.stack || '';
        }
        const key = '/flow-renewal/';
        const idx = (v) => v.indexOf(key) + key.length;
        const cut = (v) => v.slice(idx(v));
        const replace = (v) => v.replace(/(\?ver=\d*:(.*)\)?)/, ' - $2');
        stack = stack.split('\n').map((line) => replace(cut(line.trim())));
        return stack.splice(stack[0] === 'Error' ? 2 : 1);
    }

    function undef2v(globalVariable, defaultVariable) {
        return typeof globalVariable === "undefined" || (null2Void(globalVariable) === "") ? defaultVariable : globalVariable
    }

    function local2session() {
        if (!isAct("main") && !isAct("miniMain")) return;
        Object.keys(localStorage).forEach(v => {
            if (v.indexOf(_TAB_ID) === -1) return;
            if (v.indexOf("ONLY_MESSENGER-") === -1) {
                sessionStorage[v] = localStorage.getItem(v);
            }
            localStorage.removeItem(v);
        });
    }

    function session2local() {
        if (!isAct("main") && !isAct("miniMain")) return;
        Object.keys(sessionStorage).forEach(v => {
            if (v.indexOf(_TAB_ID) === -1) return;
            localStorage.setItem(v, sessionStorage[v]);
            sessionStorage.removeItem(v);
        });
    }


    function getClientOSInfo() {
        const ua = navigator.userAgent.toLowerCase();
        return {
            isWin: ua.indexOf("windows") > -1,
            isMac: ua.indexOf("macintosh") > -1,
            isWin7: ua.indexOf("windows nt 6.1") > -1,
            isWin8: ua.indexOf("windows nt 6.2") > -1,
            isWin10: ua.indexOf("windows nt 10.0") > -1,
        };
    }

    function isBrowser(key) {
        const lowUA = navigator.userAgent.toLowerCase();
        const browser = {
            ie6: lowUA.includes('msie 6'),
            ie7: lowUA.includes('msie 7'),
            ie8: lowUA.includes('msie 8'),
            ie9: lowUA.includes('msie 9'),
            ie10: lowUA.includes('msie 10'),
            ie11: lowUA.includes('msie 11'),
            ie: lowUA.includes('netscape') || lowUA.includes('trident') || lowUA.includes('msie'),
            edge: lowUA.includes('edge'),
            opera: !!window.opera,
            safari: lowUA.includes('safari') && !lowUA.includes('chrome'),
            safari3: lowUA.includes('applewebkir/5'),
            mac: lowUA.includes('mac'),
            chrome: lowUA.includes('chrome'),
            firefox: lowUA.includes('firefox'),
            whale: lowUA.includes("whale"),
        }
        return key ? browser[key.toLowerCase()] : browser;
    }

    function isElectron() {
        try {
            return ElectronApi.Comm.isElectron();
        } catch (e) {
            return window.ElectronApi ? true:false;
        }
    }

    function isGlobal() {
        return location.host.includes("morningmate");
    }

    function isLowIeBrowser() {
        return (isBrowser("msie 6")
            || isBrowser("msie 7")
            || isBrowser("msie 8")
            || isBrowser("msie 9")
            || isBrowser("msie 10"));
    }

    function getDateFormat(date, type) {
        let division;
        if (type === "HOURS") division = 60;
        else if (type === "MINUTES") division = 10;
        division && date.setMinutes(Math.ceil((date.getMinutes() / division)) * division);

        return (date.getFullYear() + '' +
            (((date.getMonth() + 1) < 10) ? ("0" + (date.getMonth() + 1)) : (date.getMonth() + 1)) + '' +
            ((date.getDate() < 10) ? ("0" + date.getDate()) : (date.getDate())) + '' +
            ((date.getHours() < 10) ? ("0" + date.getHours()) : (date.getHours())) + '' +
            ((date.getMinutes() < 10) ? ("0" + date.getMinutes()) : (date.getMinutes())) + '' + "00");
    }

    function getIEVersion() {
        let word;
        let version = "N/A";
        const agent = navigator.userAgent.toLowerCase();
        const name = navigator.appName;
        if (name === "Microsoft Internet Explorer") word = "msie ";
        else if (agent.search("trident") > -1) word = "trident/.*rv:";
        else if (agent.search("edge/") > -1) word = "edge/";
        const reg = new RegExp(word + "([0-9]{1,})(\\.{0,}[0-9]{0,1})");
        if (reg.exec(agent) != null) version = RegExp.$1 + RegExp.$2;
        return version;
    }

    function isFunc(nm) {
        const funcList = LocalUtil.getLocal("ONLY_FUNC_LIST");
        return (null2Void(funcList).length > 0) && ("" !== null2Void(nm) && ("" !== null2Void(JSON.parse(funcList)[nm])));
    }

    function diffSeconds(t1, t2) {
        const format = "YYYYMMDDHHmmss";
        const tt1 = t1 ? dayjs(t1, format) : dayjs();
        const tt2 = t2 ? dayjs(t2, format) : dayjs();
        return dayjs.duration(tt2.diff(tt1)).asSeconds();
    }

    function setCookie(name, value, expireDays, expireTime, sameSiteNone = false) {
        const todayDate = new Date();
        let strExpOpt = "", strExpDt = "";
        if (null2Void(expireDays) !== "" || null2Void(expireTime) !== "") {
            expireDays ? todayDate.setDate(todayDate.getDate() + expireDays) : todayDate.setTime(todayDate.getTime() + expireTime);
            strExpOpt = "expires=";
            strExpDt = todayDate.toGMTString() + ";";
        }
        const sameSiteOpt = sameSiteNone ? "SameSite=None;" : "";
        document.cookie = `${name}=${escape(value)}; path=/; ${strExpOpt}${strExpDt}${sameSiteOpt}${location.protocol === "http:" ? "" : "Secure;"}`;
    }

    function setDomainCookie(name, value, domain, expireDays, expireTime, isSecure = true) {
        const todayDate = new Date();
        let strExpOpt = "", strExpDt = "";
        if (null2Void(expireDays) !== "" || null2Void(expireTime) !== "") {
            expireDays ? todayDate.setDate(todayDate.getDate() + expireDays) : todayDate.setTime(todayDate.getTime() + expireTime);
            strExpOpt = "expires=";
            strExpDt = todayDate.toGMTString() + ";";
        }
        let val = name === 'eptoken' ? value : escape(value);
        const secure =  ""; //isSecure && location.protocol === "https:" ? "SameSite=None; secure" : ""; http로 토큰공유를 하기 위해 secure 옵션 해제한다.
        document.cookie = name + "=" + val + "; domain= " + domain + "; path=/; " + strExpOpt + strExpDt + secure;
    }

    function getCookie(name) {
        var cookieKey = name + "=";
        var result = "";
        var cookieArr = document.cookie.split(";");
        for (var i = 0; i < cookieArr.length; i++) {
            if (cookieArr[i][0] === " ") (cookieArr[i] = cookieArr[i].substring(1));
            if (cookieArr[i].indexOf(cookieKey) === 0) {
                result = cookieArr[i].slice(cookieKey.length, cookieArr[i].length);
                return unescape(result);
            }
        }
        return result;
    }

    function isNull(value) {
        return (value == null || value === "" || typeof (value) === undefined || value === "null" || value === "undefined" || value === undefined)
    }

    function null2Void(value, str) {
        return null2Type(value, str, "string");
    }

    function chatNull2Void(value, str) {
        return chatNull2Type(value, str, "string");
    }

    function undefined2Obj(value, obj) {
        return null2Type(value, obj, "object");
    }

    function null2Type(value, str, type) {
        if (value == null || value === "" || typeof (value) === undefined || value === "null" || value === "undefined" || value === undefined) {
            return (typeof (str) === type) ? str : "";
        }
        return value;
    }

    function chatNull2Type(value, str, type) {
        if (value == null || value === "" || typeof (value) === undefined || value === undefined) {
            return (typeof (str) === type) ? str : "";
        }
        return value;
    }

    function clog(a, b, c, d, e, f, g) {
        if (!isServerModeByHost("DEV_TEST")) return;
        console.log('console', a, b, c, d, e, f, g);
    }

    function elog(a, b, c, d, e, f, g) {
        console.log('error', a, b, c, d, e, f, g);
    }

    function isServerModeByHost(serverMode = "DEV_TEST") {
        const lHost = location.host;
        const isRealTest = () => lHost.includes("develop.flow.team") || lHost.includes("staging.flow.team") ||
            lHost.includes("release.flow.team") || lHost.includes("hotfix.flow.team")
            || lHost.includes("staging.morningmate.com") || lHost.includes("release.morningmate.com") || lHost.includes("hotfix.morningmate.com");
        const isDevTest = () => lHost.includes("localhost") || lHost.includes("flowdev.info") || lHost.includes("flowteam.info") || lHost.includes("flowtest.info")
            || lHost.includes("develop.morningmate.com") || lHost.includes("morningmate.info");
        const isSpecialSubDom = () => lHost.includes("seco.flow.team") || lHost.includes("zoomok.flow.team");
        const isReal = () => (lHost.includes("flow.team") || lHost.includes("morningmate.com")) && !isRealTest() && !isDevTest() && !isSpecialSubDom();
        return {
            "REAL": isReal(),
            "ALL_TEST": (isRealTest() || isDevTest()),
            "REAL_TEST": isRealTest(),
            "DEV_TEST": isDevTest(),
        } [serverMode]
    }

    function getServerModeByHost() {
        const lHost = location.host;

        const isRealTest = () =>
            lHost.includes("develop.flow.team")
            || lHost.includes("staging.flow.team")
            || lHost.includes("release.flow.team")
            || lHost.includes("hotfix.flow.team")
            || lHost.includes("staging.morningmate.com")
            || lHost.includes("release.morningmate.com")
            || lHost.includes("hotfix.morningmate.com");

        const isDevTest = () =>
            lHost.includes("localhost")
            || lHost.includes("flowdev.info")
            || lHost.includes("flowteam.info")
            || lHost.includes("flowtest.info")
            || lHost.includes("develop.morningmate.com")
            || lHost.includes("morningmate.info");

        const isSpecialSubDom = () =>
            lHost.includes("seco.flow.team")
            || lHost.includes("zoomok.flow.team");

        const isReal = () =>
            (lHost.includes("flow.team") || lHost.includes("morningmate.com"))
            && !isRealTest()
            && !isDevTest()
            && !isSpecialSubDom();

        if (isReal()) return "REAL";
        else if (isRealTest()) return "REAL_TEST";
        else if (isDevTest()) return "DEV_TEST";
        else if (isRealTest() || isDevTest()) return "ALL_TEST";
    }

    function getDeviceJson(clientIp) {
        const deviceNm = ElectronApi.Comm.isElectron() ? "DESKTOP" : isMobile() ? "MWEB" : isTablet() ? "TABLET" : "PC-" + getAgentName();
        let deviceId = LocalUtil.getDeviceId();
        deviceId = ("" !== deviceId) ? deviceId :
            (ServerChecker.isMobis && EnterCommonConfig.isInnerNetwork(clientIp)) ? clientIp : getRandomDeviceId();

        deviceId = ("" !== deviceId) ? deviceId : (MobisConfig.isMobis() && MobisConfig.checkInnerIp(clientIp)) ? clientIp : getRandomDeviceId();
        const returnJson = {DUID: deviceId, DUID_NM: deviceNm + "_" + deviceId};

        LocalUtil.setLocal("ONLY_DEVICE_ID", returnJson.DUID);
        LocalUtil.setLocal("ONLY_DEVICE_NM", returnJson.DUID_NM);

        // (_USER_ID !== "") && Ajax.executeApi("FLOW_DUID_C001", returnJson);
        return returnJson;

        function getAgentName() {
            const agent = navigator.userAgent.toLowerCase();
            if (agent.includes("electron")) return "ELECTRON";
            if (agent.includes("whale")) return "WHALE";
            if (agent.includes("edg")) return "EDGE";
            if (agent.includes("chrome")) return "CHROME";
            if (agent.includes("opera")) return "OPERA";
            if (agent.includes("firefox")) return "FIREFOX";
            if (agent.includes("safari")) return "SAFARI";
            else return "IE";
        }
    }

    function isTablet() {
        return !!navigator.userAgent.match(/iPad|Android/);
    }

    function isMobile() {
        const mobileFlag = /Mobile|iP(hone|od)|Windows (CE|Phone)|Minimo|Opera M(obi|ini)|BlackBerry|Nokia/;
        return navigator.userAgent.match(mobileFlag) && !navigator.userAgent.match(/iPad/);
    }

    function getRandomDeviceId() {
        return Math.round(Math.random() * 1000000) + "-" + Math.round(Math.random() * 1000) + "-"
            + Math.round(Math.random() * 1000) + "-" + Math.round(Math.random() * 1000000);
    }

    function getRandomInt(min, max) {
        const range = max - min;
        const array = new Uint16Array(1);
        crypto.getRandomValues(array);
        return min + Math.floor(array[0] / (0xFFFF + 1) * range);
    }

    function getRandomFloat() {
        const array = new Uint32Array(1);
        crypto.getRandomValues(array);
        return array[0] / 0xFFFFFFFF;
    }

    function submitForm(id, url, target, dataJson, attrJson) {
        const $tempForm = $("<form></form>").attr($.extend({}, null2Void(attrJson, {}), {
            'id': id,
            'name': id,
            'method': dataJson && (window.ElectronApi && ElectronApi.Comm.isElectron()) ? 'get' : 'post',
            'action': url,
            'target': target
        }))

        if (dataJson) {
            for (const key in dataJson) {
                if (dataJson.hasOwnProperty(key)) {
                    const inputValue = (dataJson[key] instanceof Object) ?
                        JSON.stringify(dataJson[key]) : dataJson[key];
                    const submitInput = $("<input>").attr($.extend({}, {
                        'type': 'hidden',
                        'name': key,
                        'value': inputValue,
                    }));
                    $tempForm.append(submitInput);
                }
            }
        }
        $('body').append($tempForm);
        $tempForm.submit();
        $tempForm.remove();
    }

    function copyUrl(url, successMsg) {
        const tempUrlObj = $('<textarea id="tempUrlCopyInput" type="text" style="position:absolute;top:-9999em;">').val(url);
        $("body").append(tempUrlObj);
        const $tempUrlCopyInput = $("#tempUrlCopyInput");
        $tempUrlCopyInput.select();
        try {
            document.execCommand('copy');
            if (successMsg && typeof successMsg === 'string') {
                toast('info', successMsg);
            } else {
                toast('info', i18next.t(common.alert.copiedToClipboard));
            }

            $tempUrlCopyInput.remove();
        } catch (err) {
            toast('error', i18next.t(main.alert.errorTryAgain));
            $tempUrlCopyInput.remove();
        }
    }

    function getJsonToParse(inputData) {
        try {
            return JSON.parse(inputData);
        } catch (e) {
            if (Array.isArray(inputData)) return [];
            return "";
        }
    }

    function isJson(text) {
        try {
            JSON.parse(text);
        } catch (e) {
            return false;
        }
        return true;
    }

    function tryLogout() {
        const isElectronLoginView = ElectronApi.Comm.isElectron() && (isAct('signin.act') || isAct('signIn.act'));
        if (isElectronLoginView) return;
        const isGoogleAuth = "Y" === Often.getCookie("googleLoginYn");
        if(_IsMini) $("#noticeTarget").css('display', 'none');
        $("#accountLayer").fadeOut(200);
        const confirmJson = {};
        confirmJson.contents = ({
            main: i18next.t(main.alert.logout),
            secondarySubmit: isGoogleAuth ? common.googleLogoutWarning : "",
        })
        confirmJson.callback = {
            submit: logoutDirect,
            secondarySubmit: isGoogleAuth ? googleLogout : "",
        }
        PopupDraw.closePopup();
        PopupDraw.drawConfirm(confirmJson);
    }

    /**
     * 크롬 환경 또는 브라우저에 구글을 강제로 로그아웃 (브라우저 전체 구글 로그아웃)
     */
    function googleLogout() {
        const googleLogoutUrl = "https://accounts.google.com/Logout?continue=http://google.com";
        let newWindow;

        if (ElectronApi.Comm.isElectron()) {
            newWindow = window.open(googleLogoutUrl, 'Google Logout', `top=${screen.height}, left=${screen.width}`);
        } else {
            newWindow = window.open(googleLogoutUrl);
        }

        window.setTimeout(function () {
            newWindow.close();
            logoutDirect();
        }, 100)
    }


    function logoutDirect(isNoRedirect, isNoSendSocket) {
        const isElectron = window.ElectronProcessApi;
        if (isElectron) {
            MiniState.sendStatusSocket(ProfileState.OFFLINE);
            MiniState.updateUserStateData(ProfileState.OFFLINE, setLogOut);
            if (Often.isAct("miniMain") && Often.isFunc('USE_STATUS_SERVER')) {
                MiniState.sendSocketMsgToStatusServer({TYPE: 'disconnect', STATUS: ProfileState.OFFLINE});
            } else if (Often.isAct("miniMain") && Often.isFunc('USE_STATUS_SERVER_V2')){
                StatusSocket().setConnectUserList({
                    emptyAll: true
                });
            }
        } else {
            setLogOut();
        }

        async function setLogOut() {
            const data = await Ajax.executeApi(RestApi.GET.COLABO2_LOGOUT_R001);

            LocalUtil.removeAllLocal();
            ['flowLogin', 'miniflowLogin', 'googleLoginYn', 'LAST_SUB_PATH', 'FLOW_DUID'].forEach((v) => {
                setCookie(v, '', 30 * 12);
            });
            setCookie('autoLoginCheckYN', 'N', 30 * 12); //빈값으로 지정하면 디폴트('Y') 처리되기 때문에 N으로 지정해야함.
            if(ServerChecker.isKsfc) {
                setDomainCookie("ssotoken2","",".ksfc.co.kr", -1);
                setDomainCookie("ssotoken","",".ksfc.co.kr", -1);
                setDomainCookie("eptoken","",".ksfc.co.kr", -1);
            }
            if (typeof isNoRedirect === "boolean" && isNoRedirect) return;

            window._RGSN_DTTM = ''; //세션 생성 방지

            if (!isNoSendSocket) {
                // @우성호: 소켓을 수신한 대상이 또 소켓을 쏘는 것을 방지함.
                SocketControl.logout(_USER_ID);
                await Mutil.delay(10);
            }
            if (isElectron) {
                SocketControl.closeExpiredWindows(isElectron);
                ServerChecker.isSamsungLife ? window.ElectronProcessApi.quit() : ElectronApi.User.logout(data);
                if (_IsMini) return;
            }
            if (_ENTER_YN === 'Y') {
                EnterCommonConfig.enterLogout(data);
                return;
            }

            const redirectUrl = null2Void(data['REDIRECT_URI'], getLogoutDefaultRedirectUrl());
            location.replace(redirectUrl);
        }
    }

    function getLogoutDefaultRedirectUrl() {
        const isSubDomain = "" !== Often.null2Void(window.SUB_DOM, "");
        if(isSubDomain) {
            return '/corpsignin.act';
        }

        if(_IsMini) {
            return '/miniSignIn.act';
        }

        return '/signin.act';
    }

    function getUUID() {
        return `${_USER_ID}_${LocalUtil.getDeviceId()}${'_xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        })}`;
    }

    function isMessenger() {
        if (location.href.includes("flowDrive") && typeof _RESPONSE_TYPE !== "undefined") return _RESPONSE_TYPE === "CHAT";
        if (location.href.includes("GOOGLE_DRIVE") && isAct("subscreen")) return true;
        return isAct("messenger");
    }
    function isForwardContents() {
        return location.href.includes("FORWARD_CONTENTS");
    }

    function showOrHideByFunc(funcName, $element) {
        isFunc(funcName) ? $element.show() : $element.hide();
    }

    function showOrRemoveByFunc(funcName, $element) {
        isFunc(funcName) ? $element.show() : $element.remove();
    }

    function removeOrShowByFunc(funcName, $element) {
        isFunc(funcName) ? $element.remove() : $element.show();
    }

    function hideOrShowByFunc(funcName, $element) {
        isFunc(funcName) ? $element.hide() : $element.show();
    }

    function isDeadlineExceeded(date) {
        if (null2Void(date) === "") return false;
        return Time.format(date, 'type19') < Time.currentTime('type19')
    }

    function isSameDay(date) {
        if (null2Void(date) === "") return false;
        return Time.format(date, 'type12') === Time.currentTime('type12')
    }

    function getAttrs($targetObj) {
        const objAttrArray = []
        const objLen = $targetObj.length;
        for (let len = 0; len < objLen; len++) {
            let att;
            const atts = $targetObj[len].attributes;
            const n = atts.length;
            const objJson = {};
            for (let i = 0; i < n; i++) {
                att = atts[i];
                const key = att.nodeName.toUpperCase().replace(/[-]/ig, "_");
                const isAdd = !(key === 'ID' || key === 'CLASS' || key === 'CONTENTEDITABLE' ||
                    key === 'STYLE' || key === 'DATA')
                isAdd && (objJson[key] = att.nodeValue);
            }
            objAttrArray.push(objJson);
        }
        return objAttrArray;
    }

    function getServiceName() {
        return isGlobal() ? "Morningmate" : (Often.getCookie("FLOW_LANG") === "ko" ? "플로우" : "Flow");
    }

    /**
     * url에 http://가 없으면 붙여주고 있으면 그대로 반환. isSecure이 true면 https://로 반환
     * @param url input url
     * @param isSecure (true : https:// | false : http://)
     * @returns {string} return "http:// + (your url)"
     */
    function httpTextSupplement(url, isSecure = true) {
        const httpText = isSecure ? "https://" : "http://";
        let result = url;
        if (!/http(s*):\/\//ig.test(url)) result = (null2Type(url) === "" ? "" : (httpText + url));
        return result;
    }

    /**
     * @description location 쿼리 파라미터 Json
     */
    function getLocationQueryParamJson() {
        const json = {};
        const locationSearch = window.location.search.replace("?", "");
        if (!locationSearch || locationSearch.length === 0) return json;
        const array = locationSearch.split("&");
        for (const i in array) {
            const param = array[i].split("=");
            json[param[0].toLowerCase()] = param[1];
        }
        return json;
    }

    function isGuest() {
        return (_USE_INTT_ID.startsWith("GMAIL") || _USE_INTT_ID.startsWith("KAKAO") ||
            _USE_INTT_ID.startsWith("FLOW") || _USE_INTT_ID.startsWith("APPLE")) && _ENTER_YN !== 'Y'
    }

    function includeHTML() {
        var z, i, elmnt, file, xhttp;
        /* Loop through a collection of all HTML elements: */
        z = document.getElementsByTagName("*");
        for (i = 0; i < z.length; i++) {
            elmnt = z[i];
            /*search for elements with a certain atrribute:*/
            file = elmnt.getAttribute("include-html");
            if (file) {
                /* Make an HTTP request using the attribute value as the file name: */
                xhttp = new XMLHttpRequest();
                xhttp.onreadystatechange = function () {
                    if (this.readyState == 4) {
                        if (this.status == 200) {
                            elmnt.innerHTML = this.responseText;
                        }
                        if (this.status == 404) {
                            elmnt.innerHTML = "Page not found.";
                        }
                        /* Remove the attribute, and call this function once more: */
                        elmnt.removeAttribute("include-html");
                        includeHTML();
                    }
                }
                xhttp.open("GET", file, true);
                xhttp.send();
                /* Exit the function: */
                return;
            }
        }
    }

    function isEmpty(value) {
        return typeof value == "undefined" || value == null || value === "";
    }

    async function isSubDomainUrl() {
        const dat = await Ajax.executeApi(RestApi.GET.FLOW_USE_INTT_INFM_R001, {USE_INTT_ID: window.USE_INTT_ID});
        const subDom = null2Void(dat["SUB_DOM"], "").toLowerCase();
        const hostName = location.host;
        return hostName.startsWith(`${subDom}.`);
    }

    function checkInnerIp() {
        let isInner = false;
        let ipArray = ['10.', '192.', '172.'];
        for (let i in ipArray) {
            if (_CLIENT_IP.startsWith(ipArray[i])) {
                isInner = true;
                break;
            }
        }
        return isInner;
    }

    function parseJwt(token) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        let returnData;
        try {
            returnData = JSON.parse(jsonPayload)
        } catch(error) {}
        return returnData;
    };

    /**
     * YYYYMMDDHH24MISS => YYYY-MM-DD HH24:MI
     * @param strDate YYYYMMDDHH24MISS 형태 String
     * @returns {String} YYYY-MM-DD HH24:MI 형태 String
     */
    function convertToDateString(strDate) {
        return strDate.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3 $4:$5');
    }

    function replaceFromIndex(str, index, regex, newSubstr) {
        const prefix = str.slice(0, index);
        const suffix = str.slice(index);
        const newSuffix = suffix.replace(regex, newSubstr);
        return prefix + newSuffix;
    }

    async function getClientIp() {
        const resp = await fetch("./collabo/api/flow_client_ip.jsp");
        const {result} = await resp.json();
        return result;
    }

    /**
     * @param {object} props
     * @param {string} [props.inputVersion='0_0_0']
     * @param {string} [props.mode='default']
     * @description 일렉트론용
     * @description 현재 버전이 inputVersion 미만여부 체크
     * @description * true : 현재 버전이 inputVersion에 넣어준 버전보다 낮다.
     * @description * false: 높다
     * @description
     * @description - inputVersion : 2_4_1 형식으로 입력해주세요. default: 0_0_0 ElectronProcessApi.getVersion() 의 리턴값이 2_4_1 형태라서 맞춤
     * @description - mode : en_ukComparator default는 inputVersion과 비교
     * @description - input version을 초과해야 false
     * @return {boolean}
     */
    function electronIsSmallerThanInputVersion(props = {}) {
        if(!window.ElectronProcessApi){
            Mutil.mlog(`electronIsSmallerThanInputVersion error ::::  데스크탑 환경이 아닙니다.`);
            return false;
        }
        const currentVersion = window.ElectronProcessApi?.getVersion() || Often.getCookie('electronVer')
        const {
            inputVersion = currentVersion,  // 기본값 현재버전
            mode = 'default'
        } = props;
        const appName = window.ElectronProcessApi?.getName(); // flow or Morningmate
        // lang code(flowlang)가 영어(en)일 때, morningmate 1.3.0 및 flow 2.4.1 미만 버전에서는 en으로 처리하고, 그 이상 버전에서는 en_uk와 en_us로 구분하여 처리합니다.
        const enUkStandardVersion = { Morningmate: [1, 3, 0], flow: [2, 4, 1] };
        // en_ukComparator
        if (window._ENTER_YN === 'Y' || !enUkStandardVersion[appName]) return false;

        const currentVersionArr = currentVersion.split('_').map((e) => Number(e.replace(/\D/g, '')));
        const standardVersionArr = mode === 'en_ukComparator' ? enUkStandardVersion[appName] : inputVersion.replace(/\./g,'_').split('_').map(Number);

        for (let i = 0; i < 3; i++) {
            if (currentVersionArr[i] > standardVersionArr[i]) return false;
            if (currentVersionArr[i] < standardVersionArr[i]) return true;
        }
        return false;
    }

    /**
     * @param {boolean} props.needConvert
     * @param {string} props.langCode
     * @param {boolean} props.relaunchApp
     * @description 일렉트론용
     * @description en_uk적용된 버전인지 아닌지 판단 후 setConfig
     */
    function electronSetFlowlangToElectronConfig(props = {}){
        // 디폴트 한국어
        const {needConvert = true, langCode = 'ko', relaunchApp = false} = props
        const newLangCode = needConvert && langCode.includes('en') ? 'en' : langCode;

        window.ElectronProcessApi?.setConfig({ key: 'FLOW_LANG', value: newLangCode });
        if (relaunchApp) window.ElectronProcessApi?.relaunchApp();
    }

    /**
     * @description Convert OpenGraph Image Url
     * @param {string} url
     * @return {string}
     */
    function convertExceptionalOpenGraphImageUrl(url) {
        // conversion rules
        const conversionMap = {
            naverBlog: {
                match: (url) => url.startsWith('https://blogthumb.pstatic.net/'),
                process: (url) => {
                    return url.replace('https://blogthumb.pstatic.net/', 'https://mblogthumb-phinf.pstatic.net/')
                }
            }
        }

        // convert url
        for (const key in conversionMap) {
            if (conversionMap[key].match(url)) {
                return conversionMap[key].process(url)
            }
        }

        // nothing to convert
        return url
    }

    /**
     * @description check flow tiny url
     * @param {string} url
     */
    function isTinyUrlIncluded(url) {
        const regex = new RegExp(/https?:\/\/(?:\w+?\.)?(?:flow\.team|flowtest\.info|morningmate\.(?:com|info))\/l\/[a-zA-Z0-9]+/, 'i')
        return regex.test(url)
    }

    // 임시로 테스트 만들어둠(배포때 지워야함)
    function openTestSellmateOauthWindow() {
        window.open("https://c-api.sellmate.co.kr/login?domain=api_test&client_id=145&redirect_uri=https%3A%2F%2Ftest5.flowtest.info%2Fsellmate_signin.act" , "_blank")
    }

    // 임시로 테스트 만들어둠(배포때 지워야함)
    function openTestSellmatPosOauthWindow() {
        window.open("https://sellmatepos.com/api/api_test/oauth/authorize?client_id=4&redirect_uri=https%3A%2F%2Ftest5.flowtest.info%2Fsellmatepos_signin.act&response_type=code&scope=get-order-statistics" , "_blank")
    }

    function getAdmin() {
        const obfuscatedStr = "\\x66\\x6c\\x6f\\x77\\x5f\\x61\\x64\\x6d\\x69\\x6e";
        return obfuscatedStr.split('\\x').filter(Boolean).map(hex => String.fromCharCode(parseInt(hex, 16))).join('');
    }

    function getAdminPage() {
        const obfuscatedStr = "\\x66\\x6c\\x6f\\x77\\x5f\\x61\\x64\\x6d\\x69\\x6e\\x2e\\x61\\x63\\x74";
        return obfuscatedStr.split('\\x').filter(Boolean).map(hex => String.fromCharCode(parseInt(hex, 16))).join('');
    }

    async function logoutOnBrowserClose() {
        const data = await Ajax.executeApi(RestApi.GET.COLABO2_LOGOUT_R001);
        SocketControl.logout();
        LocalUtil.removeAllLocal();
        ['flowLogin', 'miniflowLogin', 'googleLoginYn', 'LAST_SUB_PATH'].forEach((v) => {
            setCookie(v, '', 30 * 12);
        });
        setCookie('autoLoginCheckYN', 'N', 30 * 12); //빈값으로 지정하면 디폴트('Y') 처리되기 때문에 N으로 지정해야함.
        if (ServerChecker.isKsfc) {
            setDomainCookie("ssotoken2", "", ".ksfc.co.kr", -1);
            setDomainCookie("ssotoken","",".ksfc.co.kr", -1);
            setDomainCookie("eptoken","",".ksfc.co.kr", -1);
        }
        window._RGSN_DTTM = ''; //세션 생성 방지
    }
})()
