var LocalUtil = (function () {

    const stringify = v => v ? JSON.stringify(v) : '';
    const parse = (v, isArray) => {
        if (v === null) return isArray ? [] : {}
        try {
            return JSON.parse(v.replace(/\n/g, '\\n').replace(/\r/g, '\\r'));
        } catch (e) {
            return isArray ? [] : {}
        }
    }

    return {
        getLocal: getLocal,
        setLocal: setLocal,
        getLocalValue: getLocalValue,
        setLocalValue: setLocalValue,
        getLocalJson: getLocalJson,
        setLocalJson: setLocalJson,
        removeLocal: removeLocal,
        removeLocalValue: removeLocalValue,
        removeAllLocal: removeAllLocal,
        getDeviceId: function () {
            return Often.null2Void(getLocal("ONLY_DEVICE_ID"), "")
        },
        getDesktopNotiPopup: function () {
            return Often.null2Void(getLocal("ONLY_DESKTOP_NOTI_POPUP"), "Y");
        },
        getAlarmSearchFilter: function () {
            return Often.null2Void(getLocal("ALARM_SEARCH_FILTER"), "0,1,2");
        },
        getVideoConference: function () {
            return Often.null2Void(getLocal("ONLY_VIDEO_CONFERENCE"), "N");
        },
        getTaskFilter: function () {
            return Often.null2Void(getLocal("TASK_FILTER"), "ON");
        },
        getElectronYn: function () {
            return Often.null2Void(getLocal("ONLY_ELECTRON_YN"), "N");
        },
        getFeedType: function () {
            return Often.null2Void(getLocal("FEED_TYPE"), "card");
        },
        getFileSetting: function () {
            return Often.null2Void(getLocal("FILE_SETTING"), "list");
        },
        getFileFilter: function () {
            return Often.null2Void(getLocal("FILE_FILTER"), "ON");
        },
        getAutoLogin: function () {
            return Often.null2Void(getLocal("ONLY_AUTO_LOGIN_YN"), "N");
        },
        getProjectOpenYn: function () {
            return Often.null2Void(getLocalValue("CURRENT_PROJECT_SETTING", "OPEN_YN"), "N");
        },
        getProjectHiddenYn: function () {
            return Often.null2Void(getLocalValue("CURRENT_PROJECT_SETTING", "HIDDEN_YN"), "N");
        },
        getProjectMngrWrYn: function () {
            var key = $("#postPopup, #prePostPopup").is(":visible") ? "POP" : "CURRENT";
            return Often.null2Void(getLocalValue(key + "_PROJECT_SETTING", "MNGR_WR_YN"), "N");
        },
        getProjectMngrWrCmYn: function () {
            var key = $("#postPopup, #prePostPopup").is(":visible") ? "POP" : "CURRENT";
            return Often.null2Void(getLocalValue(key + "_PROJECT_SETTING", "MNGR_WR_CM_YN"), "N");
        },
        getProjectTmplType: function () {
            var key = $("#postPopup, #prePostPopup").is(":visible") ? "POP" : "CURRENT";
            return Often.null2Void(getLocalValue(key + "_PROJECT_SETTING", "TMPL_TYPE"), "1,5,4,3,2");
        },
        getProjectManagerYn: function () {
            var key = $("#postPopup, #prePostPopup").is(":visible") ? "POP" : "CURRENT";
            return Often.null2Void(getLocalValue(key + "_PROJECT_SETTING", "MNGR_DSNC"), "N");
        },
        getProjectSrchAuthYn: function () {
            var key = $("#postPopup, #prePostPopup").is(":visible") ? "POP" : "CURRENT";
            return Often.null2Void(getLocalValue(key + "_PROJECT_SETTING", "SRCH_AUTH_YN"), "N");
        },

        getProductTagYn: function () {
            var key = $("#postPopup, #prePostPopup").is(":visible") ? "POP" : "CURRENT";
            return Often.null2Void(getLocalValue(key + "_PROJECT_SETTING", "PRODUCT_TAG_YN"), "N");
        },

        getSalesReportYn:  function () {
            var key = $("#postPopup, #prePostPopup").is(":visible") ? "POP" : "CURRENT";
            return Often.null2Void(getLocalValue(key + "_PROJECT_SETTING", "SALES_REPORT_YN"), "N");
        },

        getProjectPostModDelAuthYn: function () {
            var key = $("#postPopup, #prePostPopup").is(":visible") ? "POP" : "CURRENT";
            return Often.null2Void(getLocalValue(key + "_PROJECT_SETTING", "POST_MOD_DEL_AUTH_YN"), "Y");
        },

        getEcommerceLinkedDomain: function () {
            var key = $("#postPopup, #prePostPopup").is(":visible") ? "POP" : "CURRENT";
            return Often.null2Void(getLocalValue(key + "_PROJECT_SETTING", "ECOMMERCE_LINKED_DOMAIN"), "");
        },
        getProjectReplyModDelAuthYn: function () {
            var key = $("#postPopup, #prePostPopup").is(":visible") ? "POP" : "CURRENT";
            return Often.null2Void(getLocalValue(key + "_PROJECT_SETTING", "REPLY_MOD_DEL_AUTH_YN"), "Y");
        },
        getProjectWriteEditAuth: function () {
            var key = $("#postPopup, #prePostPopup").is(":visible") ? "POP" : "CURRENT";
            return Often.null2Void(getLocalValue(key + "_PROJECT_SETTING", "EDIT_AUTH_TYPE"), "");
        },
        getProjectHomeTabCode: function () {
            return Often.null2Void(getLocalValue("CURRENT_PROJECT_SETTING", "HOME_TAB_CODE"), "");
        },
        getProjectBgColorCd: function () {
            return Often.null2Void(getLocalValue("CURRENT_PROJECT_SETTING", "BG_COLOR_CD"), "0");
        },
        getProjectAnonymousYn: function () {
            var key = $("#postPopup, #prePostPopup").is(":visible") ? "POP" : "CURRENT";
            return Often.null2Void(getLocalValue(key + "_PROJECT_SETTING", "ANOYMOUS_YN"), "N");
        },
        getProjectGubun: function() {
          return Often.null2Void(getLocalValue("CURRENT_PROJECT_SETTING", "COLABO_GB"), "");
        },
        getProjectStatus: function() {
            return Often.null2Void(getLocalValue("CURRENT_PROJECT_SETTING", "STATUS"), "");
        },
        getRoutineTabYn: () => {
            return Often.null2Void(getLocalValue("CURRENT_PROJECT_SETTING", "ROUTINE_TAB_YN"), "N");
        },
        getPopProjectBgColorCd: function () {
            return Often.null2Void(getLocalValue("POP_PROJECT_SETTING", "BG_COLOR_CD"), "0");
        },
        getPopProjectTtl: () => {
            return Often.null2Void(getLocalValue("POP_PROJECT_SETTING", "TTL"));
        },
        getPopProjectSrno: () => {
          return Often.null2Void(getLocalValue("POP_PROJECT_SETTING", "COLABO_SRNO"));
        },
        getBuyManger: function () {
            return Often.null2Void(getLocalValue("ONLY_BUY_SETTING", "MNGR_DSNC"), "N");
        },
        getBuyGrcDt: function () {
            return Often.null2Void(getLocalValue("ONLY_BUY_SETTING", "GRC_DT"), "7");
        },
        getBuyPrjMkLmtYn: function () {
            return Often.null2Void(getLocalValue("ONLY_BUY_SETTING", "PRJ_MK_LMT_YN"), "N");
        },
        getSerpYn: function () {
            return Often.null2Void(getLocalValue("ONLY_BUY_SETTING", "SERP_YN"), "N");
        },
        getPreventPcDownLoadYn: function () {
            return Often.null2Void(getLocalValue("ONLY_BUY_SETTING", "PC_DOWN_YN"), "N");
        },
        getLeftFoldCollectYn: function () {
            return Often.null2Void(Often.null2Void(getLocalJson("ONLY_LEFT_FOLD_SETTING")).COLLECT, "Y")
        },
        getAplcDt: function () {
            return Often.null2Void(getLocalValue("ONLY_BUY_SETTING", "APLC_DT"));
        },
        getStorageJson: getStorageJson,
        getCompanyInfo: getCompanyInfo,
        getPeriodFilter: function () {
            return Often.null2Void(getLocal("ONLY_SEARCH_PERIOD_FILTER"), 'sixthMonth')
        },
        getCompanyOpenProjectFilter: function () {
            return Often.null2Void(getLocal("ONLY_COMPANY_OPEN_PROJECT_FILTER"), "0");
        },
        getCompanyOpenProjectOrder: function () {
            return Often.null2Void(getLocal("ONLY_COMPANY_OPEN_PROJECT_ORDER"), (_USER_ID === "madrascheck" ? "1" : "0"));
        },
    };

    //같은 계정으로 두 가지 탭을 켜는 경우에 발생할 수 있는 이슈를 해결하기 위함
    function convertKeyByTabId(k) {
        if (k.indexOf("ONLY_") === 0) return "N_" + k; //같은 계정이라면 하나만 보관
        return "N_" + k + "_" + (window._TAB_ID || ""); //같은 계정이라도 탭 별로 정보를 달리 보관
    }

    function setLocal(k, v) {
        localStorage.setItem(convertKeyByTabId(k), v);
    }

    function getLocal(k) {
        return localStorage.getItem(convertKeyByTabId(k));
    }

    function setLocalJson(k, json) {
        localStorage.setItem(convertKeyByTabId(k), stringify(json));
    }

    function getLocalJson(k) {
        return getLocalValue(k);
    }

    function setLocalValue(k, key, value) {
        try {
            var tempJson = parse(getLocal(k)) ?? {};
            tempJson[key] = value;
            setLocal(k, stringify(tempJson));
        } catch (e) {
            //pass
        }
    }

    function getLocalValue(k, key) {
        let val;
        try {
            if (key) {
                val = parse(getLocal(k))[key];
            } else {
                val = parse(getLocal(k));
            }
        } catch (e) {
            val = Often.null2Void(getLocal(k));
        } finally {
            return val;
        }
    }

    function removeLocal(k) {
        localStorage.removeItem(convertKeyByTabId(k));
    }

    function removeLocalValue(k, key) {
        const tempJson = parse(getLocal(k));
        delete tempJson[key];
        setLocal(k, stringify(tempJson));
    }

    function removeAllLocal() {
        Object.keys(localStorage).forEach(key => {
            if (key !== "TERMLY_API_CACHE" && key !== 'N_ONLY_DESKTOP_NOTI_POPUP') {
                localStorage.removeItem(key);
            }
        });
        if(window.indexedDB) {
            window.indexedDB.databases().then((dbs) => {
                dbs.forEach((db) => {
                    indexedDB.deleteDatabase(db.name)
                });
            })
        }

    }

    function getStorageJson(extensions) {
        const storageJson = {};
        if(Object.keys(extensions).length < 1) return storageJson;
        extensions.forEach(v => storageJson[v.EXT_SERVICE_NM] = 'Y');
        return storageJson;
    }


    function getCompanyInfo({isGoogleAuth, isFeature_CHAT_AUDITOR_ALL}) {
        const dat = LocalUtil.getLocalJson('ONLY_BUY_SETTING');
        const buyCode = dat.BUY_YN || "N";
        const statusCode = dat.STTS || "N";
        const remainDate = dat.GRC_DT || "7";
        const userCnt = +(dat.USER_CNT || "1");
        const isIntt = name => globalValue.sessionInttId.indexOf(name + `_`) === 0;
        const isUsable = (key, defaultVal = "N") => (dat[key] || defaultVal) === "Y";

        return {
            isBflow: isIntt("BFLOW"),
            isFlow: isIntt("FLOW"),
            isGoogle: isIntt("GMAIL"),
            isGoogleAuth,
            isAvailableService: isUsable("USE_YN", "Y"),
            isManager: isUsable("MNGR_DSNC"),
            isDailyOnce: isUsable("DAILY_INIT_YN"),
            isDvsnTree: isUsable("DVSN_TREE_YN"),
            isCardBanner: isUsable("CARD_BANNER_YN"),
            isSerp: isUsable("SERP_YN"),
            isPreventDownloadMobile: isUsable("MB_DOWN_YN"),
            isPreventDownloadPC: isUsable("PC_DOWN_YN"),
            isEnterSns: isUsable("ENT_YN") && (isIntt("GMAIL") || isIntt("KAKAO") || isIntt("APPLE")),
            expireDttm: dat.EXPIRE_DTTM || "",
            aplcDt: dat.APLC_DT || "",
            isChatAuditor: isFeature_CHAT_AUDITOR_ALL ? (dat["CHAT_AUDITOR_YN"] || "N") : "N",
            buyCode,
            statusCode,
            remainDate,
            userCnt,
        };
    }
})();
